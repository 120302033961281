import React from 'react'
import Layout from '../../../../components/DE/LayoutDE'
import '../../../../components/ParagraphUnderPhoto/styles.css'
import { graphql } from 'gatsby'
import ServiceHeader from '../../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../o-nas/nas-tym/styles.css'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { Link } from '@reach/router'
import { ResponsiveImage } from '../../../../components/ResponsiveImage'

const NasTym = ({ data: { page, image1 } }) => {
  const URLbase = 'de/uber-uns/unser-team/'

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    desktopTablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  const { image, title } = page.frontmatter
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <ServiceHeader
        title={title}
        backgroundImage={
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        }
      />

      <div className="Paragraph--Wrapper container NasTym">
        <p>
          Unser Team besteht aus Experten für Finanz- oder Lohnbuchhaltung,
          direkte und indirekte Steuern, Unternehmensberatung und
          Gerichtsgutachtern auf dem Gebiet der Buchhaltung und Steuern. Für uns
          ist es wichtig, dass sich jedes Mitglied in seiner Spezialisierung
          ständig weiterentwickelt und alle Gesetzesänderungen verfolgt.
        </p>
        <p>
          Sie werden ausschließlich mit zertifizierten Steuerberatern und
          qualifizierten Buchhaltern zusammenarbeiten, damit alle Ergebnisse
          korrekt und genau sind und Sie sich auf uns verlassen können.
          Gleichzeitig arbeitet das gesamte Team als Ganzes effektiv, nicht nur
          in Bezug auf die berufliche Spezialisierung, sondern auch in Bezug auf
          die Persönlichkeitsprofile der Mitarbeiter und die Betonung der
          Entwicklung von Soft Skills. Dadurch können Sie nicht nur perfekte
          Steuer- und Buchhaltungsergebnisse erwarten, sondern auch eine
          professionelle Herangehensweise und eine einfache Kommunikation.
        </p>

        <p>
          Die Hauptachse des Teams besteht jedoch aus den Gründungspartnern und
          Beratern des Unternehmens, die in der Steuerberaterkammer registriert
          sind - Jan Kotala und Lukáš Eisenwort. eine langjährige Erfahrung in
          Beratungsunternehmen, bilden sich ständig weiter, halten Vorträge und
          bieten seit 2015 Dienstleistungen unter dem Markenzeichen ihres
          eigenen Unternehmens, EKP Advisory, s.r.o an.
        </p>
      </div>
      <br />
      <br />

      <div className="container" style={{ textAlign: 'center' }}>
        <div className="row align-items-center">
          <div className="col-lg-12 blue-line-three"></div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <Link to={URLbase + 'lukas-eisenwort/'}>
              <ResponsiveImage
                className="img-transform-scale"
                src="/images/team/lukas_eisenwort.jpg"
                alt="Lukáš Eisenwort"
                width="252"
              />
              <p className="NasTym--Name">Lukáš Eisenwort</p>
              <p className="NasTym--Position">Partner, Steuerberater</p>
            </Link>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <Link to={`${URLbase}jan-kotala/`}>
              <ResponsiveImage
                className="img-transform-scale"
                src="/images/team/jan_kotala.jpg"
                alt="Jan Kotala"
                width="252"
              />
              <p className="NasTym--Name">Jan Kotala</p>
              <p className="NasTym--Position">Partner, Steuerberater</p>
            </Link>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <Link to={`${URLbase}jan-tecl/`}>
              <ResponsiveImage
                className="img-transform-scale"
                src="/images/team/jan_tecl.jpg"
                alt="Jan Tecl"
                width="252"
              />
              <p className="NasTym--Name">Jan Tecl</p>
              <p className="NasTym--Position">Partner, Steuerberater</p>
            </Link>
          </div>
        </div>
        <h4 className="NasTym--H4">Managers</h4>
        <div className="underline-yellow" />
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <Link to={URLbase + 'jana-peterkova/'}>
              <ResponsiveImage
                className="NasTym--Image"
                src="/images/team/jana_peterkova.jpg"
                alt="Jana Peterková"
              />
              <p className="NasTym--Name">Jana Peterková</p>
              <p className="NasTym--Position">Manager, Steuerberater</p>
            </Link>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6">
            {/* <Link to={URLbase + 'michal-skrabis/'}> */}
            <ResponsiveImage
              className="NasTym--Image"
              src="/images/team/michal_skrabis.jpg"
              alt="Michal Škrabiš"
            />
            <p className="NasTym--Name">Michal Škrabiš</p>
            <p className="NasTym--Position">Manager, Steuerberater</p>
            {/* </Link> */}
          </div>
        </div>
        <h4 className="NasTym--H4">Steuerberater</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/zuzana_tregnerova.jpg"
              alt="Zuzana Tregnerová"
            />
            <p className="NasTym--Name">Zuzana Tregnerová</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/petr_kolar.jpg"
              alt="Petr Kolář"
            />
            <p className="NasTym--Name">Petr Kolář</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/gabriela_kopecka.jpg"
              alt="Gabriela Kopecká"
            />
            <p className="NasTym--Name">Gabriela Kopecká</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/stepan_eichinger.jpg"
              alt="Štěpán Eichinger"
            />
            <p className="NasTym--Name">Štěpán Eichinger</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/dominika_siplova.jpg"
              alt="Dominika Šiplová"
            />
            <p className="NasTym--Name">Dominika Šiplová</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jakub_beran.jpg"
              alt="Jakub Beran"
            />
            <p className="NasTym--Name">Jakub Beran</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/lucie_jeriova.jpg"
              alt="Lucie Jeriová"
            />
            <p className="NasTym--Name">Lucie Jeriová</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/anh_nguyen.jpg"
              alt="Anh Duc Nguyen"
            />
            <p className="NasTym--Name">Anh Duc Nguyen</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/ladislav_zach.jpg"
              alt="Ladislav Zach"
            />
            <p className="NasTym--Name">Ladislav Zach</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/tomas_vrana.jpg"
              alt="Tomáš Vrána"
            />
            <p className="NasTym--Name">Tomáš Vrána</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/katerina_zackova.jpg"
              alt="Kateřina Žáčková"
            />
            <p className="NasTym--Name">Kateřina Žáčková</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/miroslav_skopec.jpg"
              alt="Miroslav Skopec"
            />
            <p className="NasTym--Name">Miroslav Skopec</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Tereza Číhová"
            />
            <p className="NasTym--Name">Tereza Číhová</p>
            <p className="NasTym--Position">Steuerberater</p>
          </div>
        </Carousel>

        <h4 className="NasTym--H4">Steuerassistent</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/marek_pliska.jpg"
              alt="Marek Pliska"
            />
            <p className="NasTym--Name">Marek Pliska</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jan_hlavac.jpg"
              alt="Jan Hlaváč"
            />
            <p className="NasTym--Name">Jan Hlaváč</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/dominik_medek.jpg"
              alt="Dominik Medek"
            />
            <p className="NasTym--Name">Dominik Medek</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/katerina_mechackova.jpg"
              alt="Kateřina Měcháčková"
            />
            <p className="NasTym--Name">Kateřina Měcháčková</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/julie_babjakova.jpg"
              alt="Julie Babjáková"
            />
            <p className="NasTym--Name">Julie Babjáková</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/ludmila_cerna.jpg"
              alt="Ludmila Černá"
            />
            <p className="NasTym--Name">Ludmila Černá</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/bara_novakova.jpg"
              alt="Bára Nováková"
            />
            <p className="NasTym--Name">Bára Nováková</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/vaclav_kadlecek.jpg"
              alt="Václav Kadleček"
            />
            <p className="NasTym--Name">Václav Kadleček</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/lukas_jankovec.jpg"
              alt="Lukáš Jankovec"
            />
            <p className="NasTym--Name">Lukáš Jankovec</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/tereza_dubnova.jpg"
              alt="Tereza Dubnová"
            />
            <p className="NasTym--Name">Tereza Dubnová</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Daniel Jaško"
            />
            <p className="NasTym--Name">Daniel Jaško</p>
            <p className="NasTym--Position">Steuerassistent</p>
          </div>
        </Carousel>

        <h4 className="NasTym--H4">Finanzbuchhalter</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/radka_svobodova.jpg"
              alt="Radka Svobodová"
            />
            <p className="NasTym--Name">Radka Svobodová</p>
            <p className="NasTym--Position">Hauptbuchhalter</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/sandra_hladikova.jpg"
              alt="Sandra Hladíková"
            />
            <p className="NasTym--Name">Sandra Hladíková</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/monika_oberreiterova.jpg"
              alt="Monika Oberreiterová"
            />
            <p className="NasTym--Name">Monika Oberreiterová</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/eliska_pavlickova.jpg"
              alt="Eliška Pavlíčková"
            />
            <p className="NasTym--Name">Eliška Pavlíčková</p>
            <p className="NasTym--Position">
              Finanzbuchhalterin und Lohnbuchhalterin
            </p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jana_polanska.jpg"
              alt="Jana Polanská"
            />
            <p className="NasTym--Name">Jana Polanská</p>
            <p className="NasTym--Position">
              Finanzbuchhalterin und Lohnbuchhalterin
            </p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/linh_tran.jpg"
              alt="Tran Thi Thuy Linh"
            />
            <p className="NasTym--Name">Tran Thi Thuy Linh</p>
            <p className="NasTym--Position">Finanzbuchhalter</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/valerie_dolejsi.jpg"
              alt="Valerie Dolejší"
            />
            <p className="NasTym--Name">Valerie Dolejší</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/simona_knezickova.jpg"
              alt="Simona Kněžíčková"
            />
            <p className="NasTym--Name">Simona Kněžíčková</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/kristyna_blazkova.jpg"
              alt="Kristýna Blažková"
            />
            <p className="NasTym--Name">Kristýna Blažková</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/simona_zimmermannova.jpg"
              alt="Simona Zimmermannová"
            />
            <p className="NasTym--Name">Simona Zimmermannová</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/denisa_krizova.jpg"
              alt="Denisa Křížová"
            />
            <p className="NasTym--Name">Denisa Křížová</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/aneta_spackova.jpg"
              alt="Aneta Špačková"
            />
            <p className="NasTym--Name">Aneta Špačková</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/dagmar_stranakova.jpg"
              alt="Dagmar Straňáková"
            />
            <p className="NasTym--Name">Dagmar Straňáková</p>
            <p className="NasTym--Position">Finanzbuchhalterin</p>
          </div>
        </Carousel>

        {/* <h4 className="NasTym--H4">Back-Office</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/martin_fryc.jpg"
              alt="Martin Fryč"
            />
            <p className="NasTym--Name">Martin Fryč</p>
            <p className="NasTym--Position">Assistentin des Geschäfstführers</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jaroslava_fialova.jpg"
              alt="Jaroslava Fialová"
            />
            <p className="NasTym--Name">Jaroslava Fialová</p>
            <p className="NasTym--Position">Assistentin des Geschäfstführers</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/petra_vopatova.jpg"
              alt="Petra Vopatová"
            />
            <p className="NasTym--Name">Petra Vopatová</p>
            <p className="NasTym--Position">Leiter der Rezeption</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/tetana_symkovycova.jpg"
              alt="Teťana Symkovyčová"
            />
            <p className="NasTym--Name">Teťana Symkovyčová</p>
            <p className="NasTym--Position">Rezeptionistin</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Martina Zelenková"
            />
            <p className="NasTym--Name">Martina Zelenková</p>
            <p className="NasTym--Position">Rezeptionistin</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/ondrej_starha.jpg"
              alt="Ondřej Štarha"
            />
            <p className="NasTym--Name">Ondřej Štarha</p>
            <p className="NasTym--Position">IT-Spezialist</p>
          </div>
        </Carousel> */}
      </div>
      {/* {!!image1 && !!image1.childImageSharp ? (
        <Img
          fluid={image1.childImageSharp.fluid}
          style={{ marginBottom: '-128px', marginTop: '128px' }}
          alt={title}
        />
      ) : (
        <img src={image1.src} alt="" />
      )} */}
    </Layout>
  )
}

export default NasTym

export const pageQuery = graphql`
  query NasTymDE {
    page: markdownRemark(
      frontmatter: { slug: { eq: "de/uber-uns/unser-team" } }
    ) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    image1: file(relativePath: { eq: "nas-tym.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
